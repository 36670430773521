<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFRow :label="$pgettext('plan_category.label', 'Name')">
          <PTranslatableTextInput v-model="form.name"/>
        </PFRow>
        <PFRow :label="$pgettext('plan_category.label', 'Is Active')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.is_active"/>
        </PFRow>
        <PFRow :label="$pgettext('plan_category.label', 'Is Public')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.is_public"/>
        </PFRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";

export default {
  name: "Form",
  components: {
    PTranslatableTextInput,
    PFRow
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      form: {
        name: {},
        is_active: true,
        is_public: false,
      },
    }
  },
  async mounted() {
    this.onReset()
  },
  emits: ['save'],
  methods: {
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    },
  }
}
</script>