<template>
  <CRow form>
    <CCol col="12">
       <label v-if="label">{{ label }}</label>
    </CCol>
    <CCol col>
      <CInput v-model="text" :placeholder="placeholder" @input="onChangeValue" />
    </CCol>
    <CCol col="auto" v-if="hasLangOptions">
      <PDropDown show-item-selector
                 :placement="'bottom-end'"
                 @change="onChangeLang"
                 :items="langOptions"
                 :value="lang"
      />
    </CCol>

  </CRow>
</template>

<script>
import PDropDown from "@/domain/core/components/PDropDown.vue";
import {mapGetters} from "vuex";

export default {
  name: "PTranslatableTextInput",
  components: {PDropDown},
  props: {
    value: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    langs: {
      type: Array,
      required: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value', 'input'],
  data() {
    return {
      texts: {'it': ''},
      text: '',
      lang: 'it',
      lang_options: []
    }
  },
  watch: {
    value: function () {
      this.init()
    }, langs: function () {
      this.init()
    }
  },
  computed: {
    ...mapGetters('login', ['userLanguage']),
    langOptions() {
      const langs = this.langs ?? process.env.VUE_APP_SUPPORTED_LANGS.split(',')
      return langs.map(lang => {
        return {label: lang.toUpperCase(), value: lang}
      })
    },
    hasLangOptions() {
      return this.langOptions.length > 1
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.texts = this.value ?? {}
      this.lang = this.userLanguage
      if (!this.texts[this.lang]) {
        // Find the first text with a lang
        for (const lang in this.texts) {
          if (this.texts[lang]) {
            this.lang = lang
            break
          }
        }
      }
      this.text = this.texts[this.lang] ?? ''
    },
    onChangeValue() {
      this.texts[this.lang] = this.text
      this.$emit('update:value', this.texts)
      this.$emit('input', this.texts)
    },
    onChangeLang(newLang) {
      if (newLang instanceof Object)
        return
      this.onChangeValue()
      this.lang = newLang
      this.text = this.texts[this.lang] ?? ''
    }
  }
}
</script>

<style scoped>

</style>