<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/region/category/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewPlanCategory',
  components: {Form},
  data() {
    return {
      empty: {
        name: {},
        is_active: true,
        is_public: true,
      }
    }
  },
  computed: {
    ...mapGetters('region', [
      'regionId'
    ]),
  },
  mounted() {
    this.setRegionId(this.$route.params.region)
  },
  methods: {
    ...mapActions('region', [
      'createCategory',
      'setRegionId'
    ]),
    async onSave(data) {
      await this.createCategory(data)
      await this.$router.push({name: 'plan_category.list', params: {region: this.regionId}})
      throw new SuccessNotification(this.$pgettext('plan_category', 'Category created!'))
    }
  }
}
</script>